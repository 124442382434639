import React, { memo } from 'react'

import classNames from 'classnames'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { mapQueryToUserData } from 'lib/@getethos/utils/mapQueryToUserData'
import { getQueryString } from 'lib/@getethos/utils/utils'
import isEmpty from 'lodash.isempty'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'
import { useBrandColorsExperiment } from '@/hooks/features/useBrandColorsExperiment'
import { useBrandColorsLandingPageExperiment } from '@/hooks/features/useBrandColorsLandingPageExperiment'
import { useCobrandingOnMobile } from '@/hooks/features/useCobrandingOnMobile'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import useNewWillsHeroSection from '@/hooks/features/useNewWillsHeroSection'
import { useSingleCTAExperiment } from '@/hooks/features/useSingleCTAExperiment'
import { useSingleCTAPriceComparison } from '@/hooks/features/useSingleCTAPriceComparison'
import { useWillsFunnelFlow } from '@/hooks/features/useWillsFunnelFlow'
import { useWreathsFooterUpdate } from '@/hooks/features/useWreathsFooterUpdate'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import SingleCTADirect from '../EstimateWidget/FloatingVariation/Layouts/SingleCTADirect/SingleCTADirect'
import { Loader } from '../global/Loader/Loader'
import styles from './SingleCTA.module.scss'
import { SingleCTAInterface } from './interface'
import { BackgroundImage, CurvedOverlay, ForegroundImage } from './layouts'
import FinalExpense from './layouts/FinalExpense'
import PriceComparison from './layouts/PriceComparison'
import Trustworthy from './layouts/Trustworthy'
import Wills from './layouts/Wills'
import WillsV3 from './layouts/WillsV3'

const SingleCTA = ({
  moduleData,
  settings,
}: {
  moduleData: SingleCTAInterface
  settings: any
}) => {
  const {
    flow,
    isLeadForm,
    isLoading: isLoadingWillsFunnel,
    isTreatment: isWillsFunnelTreatment,
  } = useWillsFunnelFlow()

  const {
    isLoading: isWillsNewHeroLoading,
    isTreatment: isWillsHeroTreatment,
  } = useNewWillsHeroSection()

  const { headline, isLoading: isLoadingHeadlineRapidTest } =
    useHeadlineRapidTest()

  const {
    isControl: isPriceComparisonControl,
    isLoading: isPriceComparisonLoading,
  } = useSingleCTAPriceComparison()

  const { isLoading: isBrandColorsLoading } = useBrandColorsExperiment()
  const { isLoading: isLoadingSingleCTAExperiment, isTreatment2 } =
    useSingleCTAExperiment()
  const { isLoading: isCobrandingOnMobileLoading } = useCobrandingOnMobile()

  const { isLoading: isLoadingBrandColorsLandingPageExperiment } =
    useBrandColorsLandingPageExperiment()
  const { isLoading: isWreathsFooterUpdateLoading } = useWreathsFooterUpdate()

  const isLoading =
    isCobrandingOnMobileLoading ||
    isBrandColorsLoading ||
    isLoadingBrandColorsLandingPageExperiment ||
    isLoadingHeadlineRapidTest ||
    isLoadingSingleCTAExperiment ||
    isLoadingWillsFunnel ||
    isPriceComparisonLoading ||
    isWillsNewHeroLoading ||
    isWreathsFooterUpdateLoading

  const { policiesApproved } = useGlobalSettings(
    'policies-approved-count.md',
    settings
  )

  const featureFlagConfig = {
    useRatesCopy: moduleData.ratesCopy || false,
  }

  const { handleNavigateToApp } = useNavigateToApp()
  const queryParameters = getQueryString()
  const queryParamData = mapQueryToUserData(queryParameters || {})
  const userData = isEmpty(queryParamData) ? {} : queryParamData

  // admin override for query parameters
  if (moduleData.queryParams) {
    const paramsArray = moduleData.queryParams.split('&')
    for (const param of paramsArray) {
      const paramArray = param.split('=')
      const key = paramArray[0]
      const value = paramArray[1]
      if (key && value) {
        userData[key] = value
      }
    }
  }

  const ctaData = {
    module: 'Single CTA',
    ctaLabel: moduleData.ctaLabel,
    userData,
    newTab: moduleData.newTab,
    featureFlagConfig,
    clickthroughUrl:
      isWillsFunnelTreatment || !moduleData.clickthroughUrl
        ? ''
        : moduleData.clickthroughUrl,
    flow,
  }

  if (isLeadForm) {
    moduleData.disableNavigateToApp = true
  }

  const handleCtaClick = ({
    flow,
    label,
  }: { flow?: string; label?: string } = {}): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: ctaData.module,
        clickthroughUrl: ctaData.clickthroughUrl,
        ctaLabel: label || moduleData.ctaLabel,
        userData: ctaData.userData,
        flow: flow || ctaData.flow,
      },
      callback: null,
    })

    if (!moduleData.disableNavigateToApp) {
      handleNavigateToApp(
        ctaData.userData, //prefill user data
        typeof ctaData.newTab === 'boolean' && ctaData.newTab, // open in new tab
        false, // needs route
        ctaData.featureFlagConfig && ctaData.featureFlagConfig.useRatesCopy, // rates copy in main app
        ctaData.clickthroughUrl, // override CTA path to app with editor given URL,
        flow || ctaData.flow
      )
    }
  }

  const modules = {
    background: (
      <BackgroundImage
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
      />
    ),
    curvedOverlay: (
      <CurvedOverlay
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
      />
    ),
    direct: (
      <SingleCTADirect ctaClick={handleCtaClick} moduleData={moduleData} />
    ),
    foreground: (
      <ForegroundImage
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
        policiesApproved={policiesApproved}
      />
    ),
    finalExpense: (
      <FinalExpense moduleData={moduleData} ctaClick={() => handleCtaClick()} />
    ),
    priceComparison: (
      <PriceComparison
        ctaClick={() => handleCtaClick()}
        ctaLabel={moduleData.ctaLabel!}
        heading={headline || moduleData.heading!}
        policiesApproved={policiesApproved}
      />
    ),
    trustworthy: (
      <Trustworthy
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
        policiesApproved={policiesApproved}
      />
    ),
    wills: isWillsHeroTreatment ? (
      <WillsV3 moduleData={moduleData} ctaClick={() => handleCtaClick()} />
    ) : (
      <Wills moduleData={moduleData} ctaClick={() => handleCtaClick()} />
    ),
  }

  let imageLayout: keyof typeof modules = moduleData.imageLayout
  if (!isPriceComparisonControl) imageLayout = 'priceComparison'
  if (isTreatment2) imageLayout = 'trustworthy'
  const ModuleToRender = modules[imageLayout]

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.spearmintBackground]: isWillsHeroTreatment,
      })}
    >
      <Loader isLoading={isLoading} />
      {ModuleToRender}
    </div>
  )
}

export default memo(SingleCTA)
