export const TRUST_IMAGE_GROUPS = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742253551/00_CMS/business_insider_gray_ehoxtg.svg',
    imageAlt: 'business insider',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742253382/00_CMS/cnbc_gray_mseozl.svg',
    imageAlt: 'cnbc',
  },
]

export const TRUST_IMAGE_GROUPS_FFFFFF = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742495812/business_insider_light_qumbq8.svg',
    imageAlt: 'business insider',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742253350/00_CMS/cnbc_white_x6tpeu.svg',
    imageAlt: 'cnbc',
  },
]

export const TRUST_IMAGE_GROUPS_7E7E7E = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742253551/00_CMS/business_insider_gray_ehoxtg.svg',
    imageAlt: 'business insider',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1742253382/00_CMS/cnbc_gray_mseozl.svg',
    imageAlt: 'cnbc',
  },
]

export const TRUST_IMAGE_WREATH_UPDATE_WHITE = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1743577034/no1_business_insider_white_apaul2.svg',
    imageAlt: 'business insider',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1743577035/trusted_forbes_advisor_white_ulaiwn.svg',
    imageAlt: 'forbes advisor',
  },
]
export const TRUST_IMAGE_WREATH_UPDATE_BLACK = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1743583862/no1_business_insider_dark_jirjkp.svg',
    imageAlt: 'business insider',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1743583862/trusted_forbes_advisor_dark_kh8vrh.svg',
    imageAlt: 'forbes advisor',
  },
]
