import React, { useEffect, useMemo, useState } from 'react'

import dynamic from 'next/dynamic'

import classnames from 'classnames'
import { Button, CloudinaryImage } from 'ethos-design-system'

import { useHomePageConstants } from '@/hooks/content/useHomePageConstants'
import { useBrandColorsExperiment } from '@/hooks/features/useBrandColorsExperiment'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'
import { useWreathsFooterUpdate } from '@/hooks/features/useWreathsFooterUpdate'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../Trustworthy.module.scss'
import { SingleCTAInterface } from '../interface'

const IconV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Icon),
  { ssr: false }
)

interface TrustImage {
  alt: string
  imageUrl: string
  title: string
}

const Trustworthy = ({
  moduleData,
  ctaClick,
  policiesApproved,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  policiesApproved?: number
}) => {
  const { isTreatment: isTrustageTreatment } = useTrustageLandingPage()
  const { arrowIcon, ctaLabel, fiveMinutesHeading, heading, listType } =
    moduleData

  const { isTreatment: isWreathsFooterUpdate } = useWreathsFooterUpdate()
  const [brandColorsClassName, setBrandColorsClassName] = useState<string>()
  const [trustImages, setTrustImages] = useState<TrustImage[]>(
    features.SINGLE_CTA.trustImages
  )

  const { policiesApproved: homePagePoliciesApproved } = useHomePageConstants()

  const { headline } = useHeadlineRapidTest()
  const brandColorsExperiment = useBrandColorsExperiment()

  useEffect(() => {
    if (brandColorsExperiment.isVariant1)
      setBrandColorsClassName(styles.brandVariant1)
    else if (brandColorsExperiment.isVariant2)
      setBrandColorsClassName(styles.brandVariant2)
    else if (brandColorsExperiment.isVariant3)
      setBrandColorsClassName(styles.brandVariant3)

    if (isWreathsFooterUpdate) {
      const isDarkImages = brandColorsExperiment.isVariant3
      setTrustImages(
        isDarkImages
          ? features.SINGLE_CTA.darkWreathsTrustImages
          : features.SINGLE_CTA.wreathsTrustImages
      )
      return
    }

    if (brandColorsExperiment.isVariant3) {
      setTrustImages(features.SINGLE_CTA.darkTrustImages)
    } else {
      setTrustImages(features.SINGLE_CTA.trustImages)
    }
  }, [brandColorsExperiment, isWreathsFooterUpdate])

  const headingToShow = isTrustageTreatment
    ? 'Instant <br/> Life insurance'
    : headline || heading

  const policiesApprovedFormatted =
    homePagePoliciesApproved ||
    policiesApproved?.toLocaleString('en-US') ||
    '1,018'

  const defaultCardsInfo = [
    '**$2M** in coverage starts at **$2/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  const treatmentCardsInfo = [
    '**$500k** in coverage starts at **$1/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  let cardsInfo
  if (isTrustageTreatment) cardsInfo = treatmentCardsInfo
  else cardsInfo = defaultCardsInfo

  const bgImage = isTrustageTreatment
    ? 'https://res.cloudinary.com/getethos/image/upload/v1732263928/AdobeStock_410597901_web_cutout_1_qzmrz2.webp'
    : 'https://res.cloudinary.com/getethos/image/upload/v1736768846/a4a80674302dbb345546ec7ae210e8eb_trg43e_kc13kv.webp'

  return (
    <div className={classnames(styles.trustworthy)}>
      <div className={classnames(styles.container, brandColorsClassName)}>
        <div className={styles.mainContent}>
          <h1
            className={classnames(styles.header, brandColorsClassName, {
              [styles.fiveMinutesHeading]: fiveMinutesHeading,
            })}
          >
            <Markdown input={headingToShow || ''}></Markdown>
          </h1>
          {listType === 'checkmarks' ? (
            <div className="mb-6">
              {cardsInfo.map((card) => (
                <div
                  key={card}
                  className="mb-2 flex items-center gap-2 last:mb-0"
                >
                  <IconV2 name={'check'} sx={{ color: '#FFFFFF' }} />
                  <p className="body-s-reg text-white">{card}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.bentoContainer}>
              {cardsInfo.map((card) => (
                <div
                  key={card}
                  className={classnames(styles.box, brandColorsClassName)}
                >
                  <div className={classnames(styles.boxContent)}>
                    <Markdown input={card} />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className={classnames(styles.cta, brandColorsClassName)}>
            <Button.Unstyled
              fullWidth
              arrowIcon={!!arrowIcon}
              onClick={() => ctaClick()}
            >
              {ctaLabel}
            </Button.Unstyled>
          </div>
          <div
            className={classnames(styles.trustImages, {
              [styles.trustImagesWreaths]:
                isWreathsFooterUpdate && !brandColorsExperiment.isVariant3,
            })}
          >
            {trustImages.map((trustImage, index) => (
              <div
                key={`${trustImage.title}_${index}`}
                className={classnames(styles.trustImageWrapper, {
                  [styles.trustImageWrapperWreaths]:
                    isWreathsFooterUpdate && !brandColorsExperiment.isVariant3,
                })}
              >
                <div
                  key={`trustImage-${index}`}
                  className={classnames(styles.trustImageContainer, {
                    [styles.trustImageContainerWreaths]:
                      isWreathsFooterUpdate &&
                      !brandColorsExperiment.isVariant3,
                  })}
                >
                  <CloudinaryImage
                    publicId={trustImage.imageUrl}
                    alt={trustImage.alt}
                    className={styles.trustImage}
                    crop={CloudinaryImage.CROP_METHODS.FIT}
                    height={[55, 55, 55, 55]}
                    width={[90, 90, 90, 90]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={classnames(styles.bgImage, {
            [styles.tsBgImage]: isTrustageTreatment,
          })}
        >
          <PriorityImage
            publicId={bgImage}
            fetchpriority="high"
            alt="Father mother and child hugging"
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[600, 768, 1440, 1920]}
            width={[300, 384, 729, 972]}
          />
        </div>
      </div>
    </div>
  )
}

const features = {
  SINGLE_CTA: {
    darkTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1742253551/00_CMS/business_insider_gray_ehoxtg.svg',
        alt: 'Business Insider',
        title: 'Best no medical exam<br />term life policy',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1742253382/00_CMS/cnbc_gray_mseozl.svg',
        alt: 'CNBC',
        title: 'Best burial insurance',
      },
    ],
    trustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1742495812/business_insider_light_qumbq8.svg',
        alt: 'Business Insider',
        title: `Best no medical exam <br/> term life policy`,
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1742253350/00_CMS/cnbc_white_x6tpeu.svg',
        alt: 'CNBC',
        title: 'Best burial insurance',
      },
    ],
    wreathsTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1743577034/no1_business_insider_white_apaul2.svg',
        alt: 'Business Insider',
        title: 'Best burial insurance',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1743577035/trusted_forbes_advisor_white_ulaiwn.svg',
        alt: 'Forbes Advisor',
        title: 'Best no medical exam<br />term life policy',
      },
    ],
    darkWreathsTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1743583862/no1_business_insider_dark_jirjkp.svg',
        alt: 'business insider',
        title: 'Best burial insurance',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1743583862/trusted_forbes_advisor_dark_kh8vrh.svg',
        alt: 'Forbes Advisor',
        title: 'Best no medical exam<br />term life policy',
      },
    ],
    willsTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007064_yrt25p.svg',
        alt: '2 million families trust ethos',
        title: '2 million families trust ethos',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007066_ua57ii.svg',
        alt: 'Best value for the money',
        title: 'Best value for the money',
      },
    ],
  },
}

export default Trustworthy
